/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var lang = $('body').hasClass('lang-fr') ? 'fr' : 'en';

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Add 'js' class on HTML tag
        $('html').addClass('js');

        // Add element so we can change its layout in CSS and have a better way
        // to trigger narrow resolution (or any other where there's no other way)
        $('body').append('<div id="responsiveResTrig"></div>');

        $(window).on('resize', function(){
          UTIL.fire('common', 'setResponsiveClasses');

          // Turn tabs into a dropdown if there is not enough space
          UTIL.fire('common', 'changeTabsToDrop', 'resize');
        });

        // Fires Fancybox
        UTIL.fire('common', '_fancybox');

        // Mobile nav menu
        UTIL.fire('common', 'setNavMenus');

        // Add responsive version class on body
        UTIL.fire('common', 'setResponsiveClasses');

        // Collapsible elements
        UTIL.fire('common', 'collapse');

        // Turn tabs into a dropdown if there is not enough space
        UTIL.fire('common', 'changeTabsToDrop');

        // Collapsed text
        UTIL.fire('common', 'readMore');

        // Collapsed form
        UTIL.fire('common', 'formMore');

        // Add focus to input when add-on is clicked
        UTIL.fire('common', 'inputFocus');

        // Call BootStrap datepicker
        UTIL.fire('common', 'datepicker');

        // Header - mobile buttons
        UTIL.fire('common', 'headerMobile');

        // MatchHeight functions
        UTIL.fire('common', '_matchHeight');

        // ContactForm7 DomEvents
        UTIL.fire('common', '_cf7_events');
      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Calculate header decoline width
        UTIL.fire('common', 'decolineWidth');
      },
      _fancybox: function() {
        if($('.fancybox').length){
          var closeBtnLabel = "Close";
          if($('.lang-fr')){
            closeBtnLabel = "Fermer";
          }

          $('.fancybox').fancybox({
            maxWidth: 900,
            padding: 0,
            tpl: {
              closeBtn: '<a title="'+closeBtnLabel+'" class="fancybox-item fancybox-close" href="javascript:;"></a>',
            },
            afterClose: function() {
              // Show form
              $('form .fieldsets').show();

              // Hide confirmation message
              $('form .conf-msg').hide();
            }
          });
        }
      },
      collapse: function() {
        $('.collapse-btn').on('click', function(e) {
          e.preventDefault();
          var $this = $(this);
          var $collapse = $this.closest('.collapse-group').find('.collapse');
          $collapse.collapse('toggle');
        });
      },
      setResponsiveClasses: function(){
        // Add or remove classes on window resize
        var availableSizes = ['mobile','tablet','narrow','wide'];
        var API = $("#mobilenavMain").data( "mmenu" );

        keepSelectedSizeOnly = function(size){
          for (a = 0; a < availableSizes.length; ++a) {
            if (availableSizes[a] != size){ $('body').removeClass(availableSizes[a]);}
          }
          $('body').addClass(size);
        };

        setSize = function(size){
          // If the site doesn't already have the correct class
          if((!$('body.'+size).length)){

            if(size == 'mobile'){
              keepSelectedSizeOnly('mobile');
            }else{
              // Close the mobile nav
              API.close();

              keepSelectedSizeOnly(size);
            }

            // Header - mobile buttons
            UTIL.fire('common', 'headerMobile');
          }
        };

        // is mobile
        if($('#responsiveResTrig').css('width') == '50px') {
          setSize('mobile');
        }
        // is tablet resolution
        else if($('#responsiveResTrig').css('width') == '75px') {
          setSize('tablet');
        }
        // is wide resolution
        else if($('#responsiveResTrig').css('width') == '200px') {
          setSize('wide');
        }
        // is narrow resolution
        else {
          setSize('narrow');
        }
      },
      setNavMenus: function(){
        // Mobile nav menu
        $('#siteHeader').append('<div id="mobilenavMain" class="mobilenav"></div>');
        $('#mainnav ul').clone().appendTo($("#mobilenavMain"));

        $('.mobilenav').mmenu({
          // options
          isMenu: true
        }, {
          // configuration
          classNames: {
            list: "menu"
          },
          offCanvas: {
            pageSelector: "#page"
          },
          extensions: ["theme-dark"]
        });

        var API = $("#mobilenavMain").data( "mmenu" ).bind('closed', function () {
          $('#mainnav nav button').removeClass('is-active');
        });

        // ---- QUICK LINKS ----
        // Clone all LIs except for the Property one as it's already in the main nav
        $('#siteHeader .quickbar .quicklinks li:not(.menu-item-201)').clone().appendTo('#mobilenavMain .mm-panels > .mm-panel:first-child > ul');

        // ---- LANGUAGE ----
        $('#siteHeader .quickbar .lng li').addClass('lngSwitcher');
        var lngSwitcher = $('#siteHeader .quickbar .lng ul').html();
        $(lngSwitcher).appendTo('#mobilenavMain .mm-panels > .mm-panel:first-child > ul');

        // open the mobile nav on click
        $('#mainnav nav button').click(function() {
          $(this).addClass('is-active');
          $(this).blur();

          API.open();
        });

        // Main nav drop downs
        UTIL.fire('common', 'mainnavDropdowns');
      },
      mainnavDropdowns: function(){
        // Main nav drop downs
        mainnavPositionDropdown = function($thisItem, $dropdown){
          var $mainwrap       = $('#siteHeader');       // full width of the menu's wrapper
          var wrapperWidth    = $mainwrap.width() - 20; // we should keep 10px between a dropdown and the edge of the wrapper
          var $parentItem     = $thisItem;              // selected menu
          var parentWidth     = $parentItem.width();    // selected menu width

          $dropdown.removeClass('dropShort');

          var dropdownWidth   = $dropdown.outerWidth();      // sub menu width of the selected menu

          // if the trigger item starts past the wrapper's halfway point, the dropdown needs to be position from the right side
          if($parentItem.offset().left > ($mainwrap.offset().left + (wrapperWidth / 2))){
            $dropdown.addClass('dropRight');
          }
          // if not, we position it from the left
          else{
            $dropdown.addClass('dropLeft');
          }

          // set dropdown width so it's at least as wide as the corresponding menu item label's bg
          if(dropdownWidth < parentWidth) {
            $dropdown.addClass('dropShort');
          }
        };

        // all sub menus
        var $mainnavItemWDrop = $('#mainnav nav > ul > li.menu-item-has-children');

        // // add a span around all 1st level links
        // $('#mainnav nav > ul > li > a').wrapInner('<span></span>');

        // add a span around the text links for all 1st level links
        $('#mainnav nav > ul > li > a > span').wrapInner('<span class="label"></span>');

        // add the arrow on the links
        $mainnavItemWDrop.find('> a > span').append('<span class="arrow"></span>');

        // add a class on the sub menu
        $mainnavItemWDrop.find('> ul').wrap('<div class="drop"></div>');

        // add a class on menu to show that there are sub menus
        if($mainnavItemWDrop.length){
          $mainnavItemWDrop.parents('#mainnav nav > ul').addClass('has-drops');
        }

        if(!$('body.mobile').length){
          $mainnavItemWDrop.hoverIntent(function(){
            var $thisItem = $(this);
            mainnavPositionDropdown($thisItem, $thisItem.find('.drop ul'));
            $thisItem.addClass('on');
            $thisItem.find('ul').slideDown(250); // multi-levels not compatible, to rethink (if needed) - fpb - 2015-01-08
          }, function(){
            var $thisItem = $(this);
            var $thisItemNav = $thisItem.find('.drop');

            if($thisItemNav.is(':visible')){
              $thisItemNav.find('ul').slideUp(100, function(){
                $thisItem.removeClass('on');
              });
            }
          });
        }
      },
      headerMobile: function(){
        // Header - mobile buttons

        var $headerLogo = $('#siteHeader .logo');
        var headerInfosWidth = $('#siteHeader .infos').width();

        if($('body.mobile, body.tablet').length) {
          $headerLogo.css({
            right: headerInfosWidth
          });
        }
        else {
          $headerLogo.removeAttr('style');
        }
      },
      changeTabsToDrop: function(action) {
        // Turn tabs into a dropdown if there is not enough space

        if($('.tabs-group').length) {
          var $tabGroups = $('.tabs-group');

          // If calling this when the window is resized, we need to put back as UL before we calculate anything
          if(action && action == 'resize') {
            $tabGroups.removeClass('btn-group');
            $tabGroups.find('.dropdown-menu').removeClass('dropdown-menu');
          }

          $tabGroups.each(function(){
            var $group = $(this);
            var $tabs = $group.find('.tabs');
            var availableWidth = $group.width();
            var $tabItems = $tabs.find('> li');
            var tabsWidth = 0;
            var $btn = $group.find('> button');

            // Calculate tabs width
            $tabItems.each(function(){
              tabsWidth += $(this).outerWidth(true);
            });

            // If there is not enough space for the UL to be displayed on 1 line, show the dropdown instead
            if(tabsWidth >= availableWidth) {
              $group.addClass('btn-group');
              $tabs.addClass('dropdown-menu');

              // Change button label text to current active tab
              $btn.find('.label').html($group.find('.active a').html());
            }
            else {
              $group.removeClass('btn-group');
              $tabs.removeClass('dropdown-menu');
            }

            // We need to call some Bootstrap JS so the tab switching still works
            $tabItems.find('a').click(function(e){
              $tabItems.not($(this).parent()).removeClass('active');

              e.preventDefault();

              $(this).tab('show');

              // Change button label text to clicked tab
              $btn.find('.label').html($(this).html());
            });
          });
        }
      },
      readMore: function(){
        // Collapsed text
        toggleText = function(className){
          var termExpand = (lang == 'fr') ? "Lire la suite" : "Read More";
          var termCollapse = (lang == 'fr') ? "Réduire" : "Collapse Text";

          var symbolExpand = '+';
          var symbolCollapse = '-';

          $(className).each(function(){
            // Add Bootstrap class
            $(this).addClass('collapse');

            if(!$(this).find('+ .readmore').length){
              //$(this).after('<p class="readmore"><a href="#"><span>' + symbolExpand + ' </span>'+termExpand+'</a></p>');
              $(this).after('<p class="readmore"><a href="#">'+termExpand+'</a></p>');
            }
          });

          $(className +' + .readmore a').click(function(){
            $trig = $(this);
            $textMore = $(this).parent().prev();

            if($textMore.length){
              $textMore.collapse('toggle');

              // Change trigger text when text is expanded
              $textMore.on('shown.bs.collapse', function(){
                $trig.html(termCollapse);
              });
              // Change trigger text when text is collapsed
              $textMore.on('hidden.bs.collapse', function(){
                $trig.html(termExpand);
              });

              $trig.blur();

              return false;
            }
          });
        };

        // Set all read more texts
        toggleText('.textMore:not(.mobile)');

        // Set all read more texts specific to mobile version
        if($('body.mobile').length) {
          toggleText('.textMore.mobile');
        }
      },

      formMore: function(){
        // Collapsed text
        toggleText = function(className){
          var termExpand = (lang == 'fr') ? "Informations supplémentaires" : "Additional information";
          var termCollapse = (lang == 'fr') ? "Réduire" : "Collapse";

          var symbolExpand = '+';
          var symbolCollapse = '-';

          $(className).each(function(){
            // Add Bootstrap class
            $(this).addClass('collapse');

            if(!$(this).find('+ .readmore').length){
              $(this).after('<p class="readmore"><a href="#"><span>' + symbolExpand + ' </span>'+termExpand+'</a></p>');
            }
          });

          $(className +' + .readmore a').click(function(){
            $trig = $(this);
            $formMore = $(this).parent().prev();

            if($formMore.length){
              $formMore.collapse('toggle');

              // Change trigger text when text is expanded
              $formMore.on('shown.bs.collapse', function(){
                $trig.html('<span>' + symbolCollapse + ' </span>' + termCollapse);
              });
              // Change trigger text when text is collapsed
              $formMore.on('hidden.bs.collapse', function(){
                $trig.html('<span>' + symbolExpand + ' </span>' + termExpand);
              });

              $trig.blur();

              return false;
            }
          });
        };

        // Set all read more texts
        toggleText('.formMore:not(.mobile)');

        // Set all read more texts specific to mobile version
        if($('body.mobile').length) {
          toggleText('.formMore.mobile');
        }
      },

      inputFocus: function() {
        // Add focus to input when add-on is clicked
        if ($('form .input-group-addon')) {
          $('.input-group-addon').click(function() {
            $(this).parent().find('input, select, textarea').focus();
          });
        }
      },

      datepicker: function() {
        // Datepicker
        if ($('form .date').length) {
          $('form .date input').addClass('datepicker');
        }

        if ($('.datepicker').length) {
          $('.datepicker').datepicker({
            dateFormat: 'dd/mm/yy'
          });
        }

        // Timepicker
        if ($('form .time').length) {
          $('form .time input').addClass('timepicker');
        }

        if ($('.timepicker').length) {
          $('.timepicker').timepicker();
        }
      },

      decolineWidth: function(){
        setTimeout(function(){
          var header_width = $('#siteHeader .site-branding').outerWidth();
          var menu_width = $('#mainnav').innerWidth();
          var decoline_width = header_width - menu_width + 15;

          $('#siteHeader .decoline').animate({width: decoline_width}, 500);

          $(window).on('resize', function(){
            header_width = $('#siteHeader .site-branding').outerWidth();
            menu_width = $('#mainnav').innerWidth();
            decoline_width = header_width - menu_width + 15;

            $('#siteHeader .decoline').css('width', decoline_width);
          });
        }, 200);
      },

      _matchHeight: function() {

        if($('#featBtns').length) {
          $('#featBtns .l-region ul .img').matchHeight();
          $('#featBtns .l-region ul .title').matchHeight();
          $('#featBtns .l-region ul .text').matchHeight();
        }

        if($('#featClients').length) {
          $('#featClients .l-region ul li').matchHeight();
        }

        if($('#featBlog').length) {
          $('#featBlog .l-region .listWImgs .item .listWImgsContent').matchHeight();
          $('#featBlog .l-region .listWImgs .item .listWImgsContent header').matchHeight();
          $('#featBlog .l-region .listWImgs .item .listWImgsContent .text').matchHeight();
          $('#featBlog .l-region .listWImgs .item .listWImgsContent .btn').matchHeight();
        }

        if($('#mainContent .types').length) {
          $('#mainContent .types ul li a').matchHeight();
          $('#mainContent .types ul li .label').matchHeight();

        }

        if($('#mainContent .listing').length) {

          $('#mainContent .listing .solution-feat ul li .img').matchHeight();
          $('#mainContent .listing .solution-feat ul li .title').matchHeight();
          $('#mainContent .listing .solution-feat ul li .text').matchHeight();
          $('#mainContent .listing .solution-feat ul li .readmore').matchHeight();
        }

        if($('#mainContent .listing').length) {

          $('#mainContent .listing .solution-list ul li .img').matchHeight();
          $('#mainContent .listing .solution-list ul li .title').matchHeight();
          $('#mainContent .listing .solution-list ul li .text').matchHeight();
          $('#mainContent .listing .solution-list ul li .readmore').matchHeight();
        }

          if($('#mainContent .listing .client-list').length) {

          $('#mainContent .listing .client-list > li .back').matchHeight();
          $('#mainContent .listing .client-list > li ').matchHeight();
          $('#mainContent .listing .client-list > li .front').matchHeight();

        }
          if($('#siteFooter .about').length) {

          $('#siteFooter .about .infos .item li').matchHeight();
          // $('#siteFooter .about .infos .item li p').matchHeight();
          // $('#siteFooter .about .infos .item li a').matchHeight();
        }
      },

      // ContactForm7 DomEvents
      // Replace deprecated cf7 function on_sent_ok(), on_submit() or other events
      // https://contactform7.com/dom-events/
      _cf7_events: function() {
        // return;
        // wpcf7mailsent: Fires when an Ajax form submission has completed successfully, and mail has been sent.
        document.addEventListener( 'wpcf7mailsent', function( event ) {
          // formConfirmation(event.detail.contactFormId);
          formConfirmation();
        }, false );

        // wpcf7submit: Fires when an Ajax form submission has completed successfully, regardless of other incidents.
        document.addEventListener( 'wpcf7submit', function( event ) {
          // formScroll(event.detail.contactFormId);
          formScroll();
        }, false );
      },
    },

    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // Home FPO slider
        UTIL.fire('home', 'homeSlider');

        // Testimonials slider
        UTIL.fire('home', 'testimonialSlider');

        // Columnize text
        UTIL.fire('home', '_columnize');
      },

      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      },

      homeSlider: function(){
        $("#slider .carousel").lightSlider({
          item: 1,
          slideMove: 1,
          slideMargin: 0,
          auto: true,
          loop: true,
          speed: 1000,
          pause: 5000,
          adaptiveHeight: true,
          autoWidth:false,
          controls: false,
          pager: false,
          mode: 'fade',
        });
      },

      testimonialSlider: function(){
        $("#featTestimonials .carousel").lightSlider({
          item: 1,
          slideMove: 1,
          slideMargin: 0,
          auto: true,
          loop: true,
          speed: 1000,
          pause: 6000,
          adaptiveHeight: true,
          autoWidth:false,
          controls: true,
          pauseOnHover: true,
          mode: 'fade',
        });
      },

      _columnize: function(){
        // Columnize front page main text
        $(window).on('resize', function(){
          if(!$('#mainContent .text .column').length && (!$('body.mobile').length && !$('body.tablet').length)) {
            $('#mainContent .text p').addClass('dontsplit');
            $('#mainContent .text').columnize({ columns: 2, buildOnce: true/*, lastNeverTallest: true*/ });
            $('#mainContent .text').addClass('columnized');
          }

          if($('#mainContent .text .column').length && ($('body.mobile').length || $('body.tablet').length)) {
            $('#mainContent .text').uncolumnize();
            $('#mainContent .text').removeClass('columnized');
          }
        }).resize();
      },
    },

    'is_clients': {
      init: function() {

        // Client cards
        //UTIL.fire('is_clients', 'clientCards');
      },

      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      },

      clientCards: function() {
        // Animate cards in client list
        if($('.listing .client-list').length){


          $('.listing .client-list > li').hoverIntent(function(){
            if(!$('body.mobile').length){
              var $thisItem = $(this);

              $thisItem.find('.front').slideUp(250);
            }
          }, function(){
            if(!$('body.mobile').length){
              var $thisItem = $(this);

              $thisItem.find('.front').slideDown(250);
            }
          });
        }
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // CF7 on_sent_ok
  formConfirmation = function() {
    // Hide form
    $('form .fieldsets').slideUp(500);

    // Show confirmation message
    setTimeout(function() {
      $('form .conf-msg').fadeIn(500);
    }, 500);
  }

  // CF7 submit on_sent_ok or not
  formScroll = function() {
    // Scroll to header / conf msg
    $('html, body').animate({
        scrollTop: $("form header").offset().top -20
    }, 500);
  }

})(jQuery); // Fully reference jQuery after this point.
